import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`2020`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.imdb.com/title/tt4401570/?ref_=nm_flmg_act_1"
      }}>{`Seacole`}</a>{` — Major Harvey`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.imdb.com/title/tt5936448/?ref_=nm_flmg_act_2"
      }}>{`Riviera`}</a>{` — Gabriel Hirsch`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt7531600/?ref_=ttmi_tt"
      }}>{`Lie of You`}</a>{` — Eddie`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.imdb.com/title/tt9214832/?ref_=nm_flmg_act_1"
      }}>{`Emma`}</a>{` — Mr. Weston`}</p>
    <h3>{`2019`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.imdb.com/title/tt8001226/?ref_=nm_flmg_act_3"
      }}>{`The War of the Worlds`}</a>{` — Frederick`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.imdb.com/title/tt7715070/?ref_=nm_flmg_act_4"
      }}>{`Horrible Histories: The Movie`}</a>{` — Paulinus`}</p>
    <h3>{`2018`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt7471884/?ref_=nm_flmg_act_3"
      }}>{`Silencio`}</a>{` — Peter`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt6532374/?ref_=nm_flmg_act_1"
      }}>{`Swimming with Men`}</a>{` — Luke`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.imdb.com/title/tt4276624/?ref_=nm_flmg_act_8"
      }}>{`Krypton`}</a>{` — Ter El`}</p>
    <h3>{`2017`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt3148266/?ref_=nm_flmg_act_2"
      }}>{`12 Monkeys`}</a>{` — Sebastian`}</p>
    <h3>{`2016`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt4428038/?ref_=nm_flmg_act_4"
      }}>{`The Family`}</a>{` — John Warren`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt2078718/?ref_=nm_flmg_act_5"
      }}>{`Sacrifice`}</a>{` — Duncan Guthrie`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt4443922/?ref_=nm_flmg_act_6"
      }}>{`Native`}</a>{` — Cane`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt5437394/?ref_=nm_flmg_act_7"
      }}>{`The Nightmare Worlds of H.G. Wells`}</a>{` — Dr. Hapley`}</p>
    <h3>{`2015`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt3796698/?ref_=nm_flmg_act_8"
      }}>{`Valentine's Kiss`}</a>{` — Nicholas Whiteley`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt2403827/?ref_=nm_flmg_act_5"
      }}>{`Bone In The Throat`}</a>{` — Rupert`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt2216156/?ref_=nm_flmg_act_10"
      }}>{`Last Tango in Halifax`}</a>{` — Gary`}</p>
    <h3>{`2014`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1537594/?ref_=nm_flmg_act_11"
      }}>{`Masterpiece Contemporary`}</a>{` — Stirling Rogers`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt3494220/?ref_=nm_flmg_act_9"
      }}>{`The Crimson Field`}</a>{` — Major Edward Crecy`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt2904626/?ref_=nm_flmg_act_10"
      }}>{`Salting The Battlefield`}</a>{` — Stirling Rogers`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt2904608/?ref_=nm_flmg_act_11"
      }}>{`Turks & Caicos`}</a>{` — Stirling Rogers`}</p>
    <h3>{`2013`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt2372220/?ref_=nm_flmg_act_12"
      }}>{`The White Queen`}</a>{` — Lord Thomas Stanley`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt2338467/?ref_=nm_flmg_act_13"
      }}>{`Air Force One Is Down`}</a>{` — Arkady Dragutin`}</p>
    <h3>{`2012`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1700808/?ref_=nm_flmg_act_16"
      }}>{`Fast Girls`}</a>{` — David Temple`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1960029/?ref_=nm_flmg_act_14"
      }}>{`Secret State`}</a>{` — Felix Durrell`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0436992/?ref_=nm_flmg_act_15"
      }}>{`Doctor Who`}</a>{` — John Riddell`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt2503030/?ref_=nm_flmg_act_18"
      }}>{`The Charles Dickens Show`}</a>{` — Doctor John Snow`}</p>
    <h3>{`2011`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1888075/?ref_=nm_flmg_act_20"
      }}>{`Death In Paradise`}</a>{` — James Lavender`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1843678/?ref_=nm_flmg_act_21"
      }}>{`Scott & Bailey`}</a>{` — Nick Savage`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1744638/?ref_=nm_flmg_act_22"
      }}>{`Case Sensitive: The Point Of Rescue`}</a>{` — Mark Bretherick`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1477131/?ref_=nm_flmg_act_19"
      }}>{`Garrow's Law`}</a>{` — Sir Arthur Hill`}</p>
    <h3>{`2010`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1475582/?ref_=nm_flmg_act_3"
      }}>{`Sherlock`}</a>{` — Inspector Lestrade`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1605467/?ref_=nm_flmg_act_23"
      }}>{`Single Father`}</a>{` — Stuar`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0362357/?ref_=nm_flmg_act_24"
      }}>{`New Tricks`}</a>{` — Adrian Levene`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1371155/?ref_=nm_flmg_act_26"
      }}>{`Made In Dagenham`}</a>{` — Peter Hopkins`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0874608/?ref_=nm_flmg_act_27"
      }}>{`Inspector Lewis`}</a>{` — Alec Pickman`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1178618/?ref_=nm_flmg_act_28"
      }}>{`Wallender`}</a>{` — Alfred Harderberg`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1166893/?ref_=nm_flmg_act_25"
      }}>{`Law & Order UK`}</a>{` — John Smith`}</p>
    <h3>{`2009`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1279170/?ref_=nm_flmg_act_29"
      }}>{`The Good Times Are Killing Me`}</a>{` — Lexy`}</p>
    <h3>{`2008`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1734537/?ref_=nm_flmg_act_30"
      }}>{`Agatha Christie's Marple`}</a>{` — Lance Fortescue`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0259733/?ref_=nm_flmg_act_33"
      }}>{`Waking The Dead`}</a>{` — Colonel John Garrett`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1173494/?ref_=nm_flmg_act_31"
      }}>{`God On Trial`}</a>{` — Mordechai`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1183195/?ref_=nm_flmg_act_32"
      }}>{`Midnight Man`}</a>{` — Daniel Cosgrave`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1008108/?ref_=nm_flmg_act_34"
      }}>{`Ashes To Ashes`}</a>{` — Danny Moore`}</p>
    <h3>{`2007`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1062350/?ref_=nm_flmg_act_36"
      }}>{`The Dinner Party`}</a>{` — Roger`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1043903/?ref_=nm_flmg_act_39"
      }}>{`Clapham Junction`}</a>{` — Robin Cape`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0902348/?ref_=nm_flmg_act_37"
      }}>{`The Waiting Room`}</a>{` — George`}</p>
    <p>{`The First New Heart — Doctor Christiaan Barnard`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0795368/?ref_=nm_flmg_act_40"
      }}>{`Death At A Funeral`}</a>{` — Robert`}</p>
    <h3>{`2006`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0874679/?ref_=nm_flmg_act_41"
      }}>{`Son Of The Dragon`}</a>{` — Prince Of The North`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0462302/?ref_=nm_flmg_act_38"
      }}>{`Intervention`}</a>{` — Mark Berry`}</p>
    <h3>{`2005`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0434409/?ref_=nm_flmg_act_42"
      }}>{`V For Vendetta`}</a>{` — Lieutenant Dominic`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0491232/?ref_=nm_flmg_act_43"
      }}>{`A Waste Of Shame: The Mystery Of Shakespeare And His Sonnets`}</a>{` — William Shakespeare`}</p>
    <p>{`Spooks — William Sampson`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0432432/?ref_=nm_flmg_act_45"
      }}>{`Rag Tale`}</a>{` — Eddy Taylor`}</p>
    <h3>{`2004`}</h3>
    <p>{`Theatre Biz — Himself / Lord Illingworth`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0363908/?ref_=nm_flmg_act_46"
      }}>{`Pride`}</a>{` — Linus`}</p>
    <p>{`A New Religion (Promotional Short) — Vinny`}</p>
    <h3>{`2003`}</h3>
    <p>{`Charles II: The Power And The Passion — The Duke Of Buckingham`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0260615/?ref_=nm_flmg_act_49"
      }}>{`The Forsyte Saga`}</a>{` — Jolyon Forsyte`}</p>
    <h3>{`2002`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0283160/?ref_=nm_flmg_act_50"
      }}>{`Extreme Ops`}</a>{` — Jeffrey`}</p>
    <h3>{`2001`}</h3>
    <p>{`Snake (Promotional Short) — Matt`}</p>
    <h3>{`2000`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0256420/?ref_=nm_flmg_act_51"
      }}>{`Take A Girl Like You`}</a>{` — Patrick Standish`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0218559/?ref_=nm_flmg_act_52"
      }}>{`Room To Rent`}</a>{` — Mark`}</p>
    <h3>{`1999`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0144178/?ref_=nm_flmg_act_53"
      }}>{`Dreaming Of Joseph Lees`}</a>{` — Joseph Lees`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0218705/?ref_=nm_flmg_act_54"
      }}>{`All My Loved Ones`}</a>{` — Nicholas Winton`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0178130/?ref_=nm_flmg_act_55"
      }}>{`Cleopatra`}</a>{` — Octavia`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0181201/?ref_=nm_flmg_act_56"
      }}>{`The Blonde Bombshell`}</a>{` — Dennis Hamilton`}</p>
    <h3>{`1998`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0274865/?ref_=nm_flmg_act_58"
      }}>{`Soldier's Leap (Promotional Short)`}</a>{` — Christia`}</p>
    <p>{`Sweet Revenge (The Revengers' Comedies) — Oliver Knightly`}</p>
    <h3>{`1997`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0118698/?ref_=nm_flmg_act_60"
      }}>{`Bent`}</a>{` — Officer On The Train`}</p>
    <p>{`Comic Relief Red Nose Day 1997 - Rest & Recreation — Mr. Farcy`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0119723/?ref_=nm_flmg_act_59"
      }}>{`Mrs. Dalloway`}</a>{` — Septimus Warren Smith`}</p>
    <h3>{`1996`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0115387/?ref_=nm_flmg_act_61"
      }}>{`The Tenant Of Wildfell Hall`}</a>{` — Arthur Huntingdon`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0116102/?ref_=nm_flmg_act_64"
      }}>{`Different For Girls`}</a>{` — Paul Prentice`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0113425/?ref_=nm_flmg_act_63"
      }}>{`The Innocent Sleep`}</a>{` — Alan Terry`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0116643/?ref_=nm_flmg_act_62"
      }}>{`Intimate Relations`}</a>{` — Harold Guppy`}</p>
    <h3>{`1995`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0106021/?ref_=nm_flmg_act_66"
      }}>{`Harry`}</a>{` — Dominic Collier`}</p>
    <h3>{`1994`}</h3>
    <p>{`One Foot In The Past — Himself`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0110428/?ref_=nm_flmg_act_67"
      }}>{`The Madness Of King George`}</a>{` — Captain Greville`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0109650/?ref_=nm_flmg_act_69"
      }}>{`Doomsday Gun`}</a>{` — Jone`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0110741/?ref_=nm_flmg_act_68"
      }}>{`Open Fire`}</a>{` — David Martin`}</p>
    <h3>{`1993`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0297625/?ref_=nm_flmg_act_70"
      }}>{`Screen One: Royal Celebration`}</a>{` — Nei`}</p>
    <h3>{`1992`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0183767/?ref_=nm_flmg_act_72"
      }}>{`The Sheltering Desert`}</a>{` — Hermann Korn`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0092379/?ref_=nm_flmg_act_74"
      }}>{`Inspector Morse`}</a>{` — Bill`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0104237/?ref_=nm_flmg_act_73"
      }}>{`Damage`}</a>{` — Martyn Fleming`}</p>
    <h3>{`1991`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0103243/?ref_=nm_flmg_act_75"
      }}>{`Where Angels Fear To Tread`}</a>{` — Philip Herriton`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0102742/?ref_=nm_flmg_act_71"
      }}>{`A Private Affair (Una Questione Privata)`}</a>{` — Milton`}</p>
    <h3>{`1990`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0100376/?ref_=nm_flmg_act_77"
      }}>{`The Plot To Kill Hitler`}</a>{` — Axel von dem Bussche`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0099255/?ref_=nm_flmg_act_76"
      }}>{`The Children`}</a>{` — Gerald Ormerod`}</p>
    <h3>{`1988`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0095274/?ref_=nm_flmg_act_78"
      }}>{`A Handful Of Dust`}</a>{` — John Beaver`}</p>
    <h3>{`1987`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0092353/?ref_=nm_flmg_act_79"
      }}>{`Fortunes Of War`}</a>{` — Simon Boulderstone`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0093512/?ref_=nm_flmg_act_80"
      }}>{`Maurice`}</a>{` — Alec Scudder`}</p>
    <h3>{`1985`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0091867/?ref_=nm_flmg_act_81"
      }}>{`A Room With A View`}</a>{` — Freddy Honeychurch`}</p>
    <h3>{`1984`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0227319/?ref_=nm_flmg_act_82"
      }}>{`Puccini`}</a>{` — Tonio`}</p>
    <h3>{`1983`}</h3>
    <p>{`Romance Theatre: Mona — Jim Atkins`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0496627/?ref_=nm_flmg_act_83"
      }}>{`St. Ursula's In Danger`}</a>{` — Teddy`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0285148/?ref_=nm_flmg_act_84"
      }}>{`Good And Bad At Games`}</a>{` — Guthrie`}</p>
    <p>{`Starting Out — John### 1981`}</p>
    <p>{`Union Matters — John Neil`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0285148/?ref_=nm_flmg_act_84"
      }}>{`Vice Versa`}</a>{` — Tipping`}</p>
    <h3>{`1979`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0078611/?ref_=nm_flmg_act_87"
      }}>{`The Famous Five`}</a>{` — Yan`}</p>
    <h3>{`1978`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0077069/?ref_=nm_flmg_act_88"
      }}>{`Return Of The Saint`}</a>{` — Prefect`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      