import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Box, Text, Button, Divider } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"

const shortcodes = { Box, Text, Button, Divider }

export default function PageTemplate({ children, pageContext }) {  
  const { title } = pageContext.frontmatter
  
  return (
    <Layout>
      <SEO title={title} />
      <Text as="h2" variant="h2">{title}</Text>
      <MDXProvider components={shortcodes}>{children}</MDXProvider>
    </Layout>
  )
}